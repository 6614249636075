import { SystemProps, Flex } from '@storyofams/react-ui'
import { Text, Button } from '~components'
import { SectionWrapper } from '~components/common/SectionWrapper';

type ReviewsSmallProps = {
  content: {
    _uid?: string;
    stars?: number;
    stars_text?: string;
    link_text?: string;
    link?: {
      cached_url?: string,
    };
    font_color?: {
      color?: string,
    };
    background_color?: {
      color?: string,
    };
  };
  first?: boolean;
} & SystemProps;

export const ReviewsSmall = ({ content }: ReviewsSmallProps) => {
  return (
    <SectionWrapper
      backgroundColor={
        content?.background_color
          ? content?.background_color.color
          : 'auto'
      }
    >
      <Flex
        marginTop={'0 !important'}
        p={1.5}
        px={2}
        gap={'8px'}
        alignItems={'center'}
        flexWrap={'wrap'}
        justifyContent={'center'}
      >
        <Flex gap={'8px'} width={'max-content'}>
          {content?.stars && (
            <Flex>
              {Array(Number(content.stars)).fill(0).map((e, i) => <Text key={content._uid + '_' + i} color={!!content?.font_color ? content?.font_color.color : 'initial' } fontSize={[1.5, '18px']} fontWeight={'700'}>⭐</Text>)}
            </Flex>
          )}
          {content?.stars_text && (<Text color={!!content?.font_color ? content?.font_color.color : 'initial' } fontSize={[1.5, '18px']}>{content?.stars_text}</Text>)}
        </Flex>
        {content?.link_text && (
          <Button
            variant='link'
            to={content?.link?.cached_url}
            fontSize={[1.5, '18px']}
            fontWeight={'700'}
            width={'max-content'}
            textDecoration={'none'}
            color={!!content?.font_color ? content?.font_color.color : 'initial' }
          >
            {content?.link_text}
          </Button>
        )}
      </Flex>
    </SectionWrapper>
  );
};
