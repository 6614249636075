import { Box, Flex } from '@storyofams/react-ui';
import styled from 'styled-components';

export const SectionWrapper = ({ children, backgroundColor, justifyContent, flexDirection, mdBgColor }: {
    children: any;
    backgroundColor?: any;
    justifyContent?: any;
    flexDirection?: any;
    mdBgColor?: any;
  }) => {
    /* TODO: Fix media ruled based bg color */
    if(!!mdBgColor){
      const MediaBgColorFlex = styled(Flex)`
        @media screen and (min-width: 768px){
          background-color: ${mdBgColor};
        }
      `;

      return (
        <MediaBgColorFlex justifyContent={'center'} width={'100%'} backgroundColor={!!backgroundColor ? backgroundColor : ''}>
          <Box width={'100%'} maxWidth={'1440px'}>
            {children}
          </Box>
        </MediaBgColorFlex>
        )
    }

    return (
    <Flex justifyContent={'center'} width={'100%'} backgroundColor={!!backgroundColor ? backgroundColor : ''}>
      <Box width={'100%'} maxWidth={'1440px'}>
        {children}
      </Box>
    </Flex>
    )
}